<template>
	<div>
		<PageHeader :title="$route.meta.title">
			<div class="tables-board-header">
				<div v-if="totalTables > 0">
					<span class="header-label">Mesas ocupadas:</span>
					<span class="header-value"
						>{{ occupieds }}/{{ totalTables }}</span
					>
				</div>
				<div v-if="totalTables > 0" class="slider-size">
					<a-tooltip title="Zoom">
						<a-icon
							type="zoom-in"
							style="font-size: 18px; padding-top: 4px;"
						/>
					</a-tooltip>
					<a-slider
						:value="sliderSize"
						:min="90"
						:max="160"
						style="
							float: right;
							width: calc(100% - 30px);
							margin: 7px 0;
						"
						:tooltip-visible="false"
						@change="sliderChange"
					/>
				</div>
			</div>
		</PageHeader>
		<TablesList @edit="handleEdit" />
		<TableModal ref="tableModal" title="Editar Mesa" />
	</div>
</template>

<script>
import { PageHeader } from '../../components'
import { TableModal, TablesList } from './components'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'TablesTest',
	components: {
		PageHeader,
		TableModal,
		TablesList,
	},
	computed: {
		...mapGetters({
			totalTables: 'tables/totalTables',
			allTables: 'tables/allTables',
			sliderSize: 'tables/sliderSize',
		}),
		occupieds: function () {
			return this.allTables.reduce(function (cc, curr) {
				if (curr.orders.length > 0) cc++
				return cc
			}, 0)
		},
	},
	methods: {
		...mapActions('tables', ['sliderChange']),
		setShowModal() {
			this.$refs.tableModal.setShowModal()
		},
		handleEdit(index) {
			this.$refs.tableModal.setShowModal(index)
		},
	},
}
</script>

<style lang="less" scoped>
.tables-board-header {
	display: flex;
	justify-content: space-between;
	width: 85%;
	align-items: center;

	.header-label {
		font-size: 16px;
		font-weight: bold;
		color: #000;
	}

	.header-value {
		padding-left: 5px;
	}

	.slider-size {
		width: 25%;
	}
}
</style>
